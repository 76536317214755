namespace pixi_tilemap {

    export const Constant = {
        maxTextures: 4,
        bufferSize: 2048,
        boundSize: 1024,
        boundCountPerBuffer: 4,
    }

}
